
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '../store/modules/auth'
import NaviBar from '@/components/NaviBar.vue'
import service from '@/utils/request'
import { Note } from '@/models/message'
import dayjs from 'dayjs'
import { Journey, PlanStatics } from '@/models/plan'
import { CompleteStatus, StandardLog, TotalData } from '@/models/practice'
import wx from 'weixin-js-sdk-ts'
import { User } from '@/models/user'

@Options({
  components: {
    NaviBar
  }
})
export default class UserDetail extends Vue {
  authStore = AuthStore
  activeName = 'statistics'
  notes: Note[] = []
  planId = ''
  userId = ''
  staticsData: PlanStatics = {
    lifePracticeTotalDays: 0,
    lifePracticeTotalNum: 0,
    standardExerciseTotalDays: 0,
    standardExerciseTotalDurationMin: 0,
    standardExerciseTotalNum: 0,
    user: {
      appCode: '',
      avatar: '',
      exp: 0,
      gender: '',
      iat: 0,
      iss: '',
      name: '',
      phone: '',
      sub: '',
      userId: '',
      username: '',
      roles: ''
    },
    userId: 0
  }

  state = {
    loading: false,
    finished: false
  }

  params = {
    pageNum: 1,
    pageSize: 10,
    sort: '-createTime',
    userId: '',
    planId: ''
  }

  mounted () {
    this.userId = this.$route.params.userId + ''
    this.planId = this.$route.params.planId + ''
    this.params.userId = this.userId
    this.params.planId = this.planId

    this.loadPlanPracitceData()
  }

  getLocalMonth (date: string) {
    return dayjs(date).month() + 1
  }

  getLocalDay (date: string) {
    return dayjs(date).date()
  }

  loadNoteData () {
    service.get('/mindup/mini/notes', {
      params: this.params
    }).then(res => {
      if (res.data.content.length > 0) {
        this.notes = this.notes.concat(res.data.content)
        this.state.loading = false
      } else {
        this.state.finished = true
      }
      this.params.pageNum += 1
    })
  }

  tapNote (index: number) {
    console.log(index)
    this.$router.push({
      name: 'InformationDetail',
      params: { noteId: this.notes[index].id }
    })
  }

  loadPlanPracitceData () {
    service.get('/mindup/mini/statistics/plan-practice-statistics/get', {
      params: { planId: this.planId, userId: this.userId }
    }).then(res => {
      this.staticsData = res.data
    })
  }
}
